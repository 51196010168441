import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(err => {
                if ([401, 403].includes(err.status) && this.authService.isTokenExpired()) {
                    // Auto logout if 401 or 403 response returned from the API
                    this.authService.logout();
                }
                const error = err.error?.message || err.statusText;
                console.error(err);
                return throwError(() => error);
            })
        );
    }
}
