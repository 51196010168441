import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from './services/token-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'Batch Checker';
  isIframe = false;
  isLogin = false;
  hasToken = false;

  private readonly _destroying$ = new Subject<void>();
  constructor(private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router) { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.isLogin = this.msalService.instance.getAllAccounts().length > 0;
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$)).subscribe(async () => {
      await this.setLoginDisplay();
    });
    this.tokenService.tokenChange.subscribe((token) => {
      if (token != undefined && token != null) {
        this.hasToken = true;
      }
    });
  }

  async setLoginDisplay() {
    if (this.authService.isTokenExpired()) {
      this.logout();
    } else {
      this.isLogin = this.msalService.instance.getAllAccounts().length > 0;
      if (this.isLogin) {
        const accessToken = this.tokenService.getToken();
        if (accessToken == null || accessToken == undefined) {
          await this.authService.GetAccessToken();
        }
      } else {
        this.login();
      }
    }
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}