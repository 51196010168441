import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { APIResponseGet } from '../../models/api-response/api-response';
import { isValidResponseData } from '../../models/helper';
import { CheckHealthResponse } from '../../models/api-response/check-health-response';
import { TokenService } from '../../services/token-service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  serverName = '';
  databaseName = '';
  constructor(private dashboardService: DashboardService, private tokenService: TokenService) {

  }

  ngOnInit(): void {
    const token = this.tokenService.getToken();

    if (token != undefined && token != null) {
      this.checkHealth();
    } else {
      setTimeout(() => {
        this.checkHealth();
      }, 5000);
    }


  }
  checkHealth() {
    this.dashboardService.checkHealth().subscribe((data: APIResponseGet<CheckHealthResponse>) => {
      if (isValidResponseData<CheckHealthResponse>(data)) {
        this.serverName = data.data.serverName;
        this.databaseName = data.data.databaseName;
      }
    });
  }
}
