import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest } from '../app/services/auth-config';
import { CustomErrorHandler } from './custom-error.handler';
import { environment } from '../environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog-service';
import { HTTPInterceptor } from './services/http-Interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, string[]>();
    protectedResourceMap.set(environment.redirectUri, [`${environment.clientId}/.default`]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}
@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        MsalModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        HeaderComponent,
        FooterComponent,
        MatProgressSpinnerModule,
        MatSliderModule,
        LoaderComponent,
        MatTooltip,
        RouterOutlet, RouterLink, RouterLinkActive], providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        }, {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        }, {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        }, {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
            MsalService,
            MsalGuard,
            MsalBroadcastService,
            ConfirmationDialogService,
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        provideAnimationsAsync(),
        provideAnimationsAsync('noop'), provideHttpClient(withInterceptorsFromDi())]
})
export class AppModule { }