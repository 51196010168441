<div class="loader-background" *ngIf="isLoading">
    <svg width="130" height="130" [style.background-color]="importedPercentage !== null && importedPercentage >= 0 ? 'white' : 'none'">
        <defs>
            <clipPath id="cut-off">
                <rect x="0" y="65" width="130" height="130" />
            </clipPath>
            <linearGradient id="gradient">
                <stop offset="0" stop-color="#59b189"></stop>
                <stop offset="75%" stop-color="#59b189" stop-opacity="0"></stop>
            </linearGradient>
        </defs>
        <circle cx="65" cy="65" r="60" stroke="#eff8f3" stroke-width="15" fill="none" opacity="1"></circle>
        <circle cx="65" cy="65" r="60" stroke="url(#gradient)" stroke-width="15" fill="none" clip-path="url(#cut-off)">
        </circle>
    </svg>
    <span *ngIf="importedPercentage !== null && importedPercentage >= 0" class="percentage">{{importedPercentage}}%</span>
</div>