import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'automated-processing', loadChildren: () => import('./components/automated-processing/automated-processing.module').then(a => a.AutomatedProcessingModule),
  },
  {
    path: 'about', loadChildren: () => import('./components/about/about.module').then(a => a.AboutModule),
  },
  {
    path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(a => a.AdminModule),
  },
  
  { path: '**',  redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
