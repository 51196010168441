<div class="bg-body-tertiary footer">
    <div class="container-fluid py-2">
        <div class="row">
            <div class="col-auto">
                © 2024 ESOS Ltd  All Rights Reserved
            </div>
            <div class="col-auto ms-auto text-primary">
                Importing to : {{serverName}} | Database - {{databaseName}}
            </div>
        </div>
    </div>
</div>