import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Loading } from './loading';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  isLoading = false;
  importedPercentage!: number | null;

  constructor(private changeDEtectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.importedPercentage = null;
    Loading.onChange.subscribe(data => {
      this.importedPercentage = data.Percentage != undefined ? data.Percentage : null;
      this.isLoading = data.isShow;
      this.changeDEtectorRef.detectChanges();
    });
  }
}
